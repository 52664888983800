import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from "@ezteach/_services/user.service";
import { CommonValidationStatusEnum } from '@ezteach/api/models/common-validation-status-enum';
import { UserSerchService } from '@ezteach/api/services/user-search.service';
import { ProfileService } from '@ezteach/profile/profile.service';
import { UserAvatarBackgroundPipe } from '@ezteach/shared/pipes/user-avatar-background.pipe';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'modal-messages-members',
  templateUrl: 'modal-messages-members.component.html',
  styleUrls: ['./modal-messages-members.component.scss'],
  providers: [UserAvatarBackgroundPipe],
})
export class ModalMessagesMembers implements OnInit {
  profile;
  environment;
  focused = false;
  candidate = '';
  chatUserControl = new UntypedFormControl();
  filteredUsers = [];
  canSendMessage = false;
  isShownChatUserControl = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalMessagesMembers>,
    private userSearchService: UserSerchService,
    private userAvatarBackgroundPipe: UserAvatarBackgroundPipe,
    private userProfileService: ProfileService,
    private translocoService: TranslocoService,
    private userService: UserService
  ) { }

  ngOnInit() {
    const user = this.userService?.userData$?.value;
    this.isShownChatUserControl = user?.id > 0;

    this.chatUserControl.disable();
    this.isApprovedTutorProfile();

    this.chatUserControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        startWith(''),
        switchMap(value => this.getUsersList(value)),
      )
      .subscribe(({ data }) => {
        this.filteredUsers = data;
      });
  }

  madeInFocus(event: FocusEvent) {
    this.focused = true;
  }

  madeOutFocus(event) {
    this.focused = false;
  }

  get isTutor() {
    return this.userService.userData$.value.isTutor ? $localize`участника` : $localize`организатора`;
  }

  get placeholder() {
    return this.translocoService.translate(`Введите имя ${this.isTutor}`);
  }

  get haveAddedUsers() {
    return this.data.list.length !== 0;
  }

  isApprovedTutorProfile() {
    return this.userProfileService.validationStatus().subscribe(r => {
      this.canSendMessage =
        r.data.validationStatusId.toLocaleLowerCase() === CommonValidationStatusEnum.Approved.toLocaleLowerCase();
      if (this.canSendMessage) {
        this.chatUserControl.enable();
      }
    });
  }

  startChat(id) {
    this.dialogRef.close({
      status: 'start',
      id,
    });
  }

  addChatUser(user) {
    if (this.data.list.findIndex(u => u.id == user.id) == -1) this.data.list.unshift(user);
    this.chatUserControl.setValue('');
    this.candidate = null;
  }

  image(user) {
    if (user.avatarFileName) {
      return this.userAvatarBackgroundPipe.transform(user);
    } else {
      return null;
    }
  }

  initials(user) {
    return user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase();
  }

  close() {
    this.dialogRef.close({
      status: 'close',
    });
  }

  getUsersList(user) {
    if (user && user.trim()) {
      return this.userSearchService.getUsersList(user).pipe(untilDestroyed(this));
    } else {
      return of({ data: [] });
    }
  }
}

<modal-ie-warning *ngIf="!isValidBrowser() && isAuth"></modal-ie-warning>

<div
  class="page-container"
  [ngClass]="{ 'page-container-mobile': isMobile }"
  *ngIf="isAuth && isValidBrowser()"
>
  <ezteach-group-lesson-embedded-window-merge
    [isOnGroupLesson]="isGroupLesson"
    [style.display]="isGroupLesson || isGroupLessonWait ? 'none' : ''"
  >
  </ezteach-group-lesson-embedded-window-merge>
  <header
    [isTutor]="isTutor"
    [ngClass]="{
      'header-group-full-state': fullViewPortGroupLessonService.isStateFullMode,
      'header-group-simple-state': fullViewPortGroupLessonService.isStateToSimpleMode
    }"
  ></header>
  <div
    class="page-content-wrapper"
    [ngClass]="{
      withoutFooter: isMobileWithoutFooterPage,
      'page-content-wrapper-whiteboard': isWhiteBoard
    }"
  >
    <div
      class="aside-back"
      *ngIf="!isMobile"
      [ngClass]="{ 'aside-back-collapsed': asideIsCollapsed }"
    ></div>

    <aside
      class="aside"
      [ngClass]="{
        collapsed: asideIsCollapsed,
        'aside-group-full-state ': fullViewPortGroupLessonService.isStateFullMode,
        'aside-group-simple-state': fullViewPortGroupLessonService.isStateToSimpleMode
      }"
    >
      <nav
        class="nav"
        [unreadMessagesCount]="unreadMessagesCount"
        [asideIsCollapsed]="asideIsCollapsed"
      ></nav>

      <div
        class="aside_vervion"
        [ngClass]="{
          collapsed: asideIsCollapsed
        }"
      >
        v {{ appVersion }}
      </div>

      <a
        class="aside_collapse"
        *ngIf="isDesktop && !fullViewPortGroupLessonService?.isStateFullMode"
        [ngClass]="{
          collapsed: asideIsCollapsed,
          aside_collapsed: asideIsCollapsed
        }"
        (singleClick)="collapse()"
        ezteachAntiQuickClick
      >
        <img
          class="aside_collapse__icon"
          [src]="asideIsCollapsed ? 'assets/img/svg/icon-sidebar-right.svg' : 'assets/img/svg/icon-sidebar-left.svg'"
        />
        <span
          class="aside_collapse__label"
          *ngIf="!asideIsCollapsed"
        >{{ 'Свернуть' | transloco }}</span>
      </a>
    </aside>
    <main
      class="main"
      #mainBlock
      [ngClass]="{
        'group-lesson-view': isGroupLesson && isMobile,
        'without-padding': isWithoutPaddingPage,
        collapsed: asideIsCollapsed,
        'main-full-state': fullViewPortGroupLessonService.isStateFullMode,
        'main-for-call': isWithoutPaddingPage
      }"
      (resized)="onMainBlockResize()"
    >
      <!--profile-warning
        *ngIf="showWarningProfile && !disableValidationNotification && !isWarningProfilePage"
        [validationStatus]="userValidationStatus"
        [userProfile]="userProfile"
      ></profile-warning-->

      <router-outlet (activate)="onRouterOutletActivate($event)"> </router-outlet>
    </main>
  </div>

  <footer
    class="footer"
    *ngIf="
      !isGroupLesson &&
      !isGroupLessonWait &&
      !(isMobile && isMessagesComponent) &&
      !(isMobile && isCalendarComponent) &&
      !isWhiteBoard &&
      !(isMobile && isMobileWithoutFooterPage)
    "
    [isTutor]="isTutor"
    [menuCollapsed]="asideIsCollapsed"
  ></footer>
</div>
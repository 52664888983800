import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SignalrService } from './signalr.service';

@Injectable({
  providedIn: 'root',
})
export class IndicatorService {
  readonly onUpdateLeftMenuMyLessons = new Subject<any>();
  readonly onUpdateLeftMenuMyBoard = new Subject<any>();

  constructor(
    private signalRService: SignalrService,
  ) {
    this.signalRService.onLessonCanceled.subscribe(() => {
      this.onUpdateLeftMenuMyLessons.next();
    });
  }
}

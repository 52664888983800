import { DOCUMENT, DatePipe, } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PageVisibilityService } from '@ezteach/_services/page-visibility.service';
import { SignalrChatService } from '@ezteach/_services/signalr.chat.service';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { UserService } from '@ezteach/_services/user.service';
import { ChatMessageAttachment } from '@ezteach/api/models';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { ChatMessage } from '@ezteach/api/models/chat-message';
import { ChatMessageAttachmentUrls } from '@ezteach/api/models/chat-message-attachment';
import { ChatService } from '@ezteach/api/services/chat.service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { UserAvatarBackgroundPipe } from '@ezteach/shared/pipes/user-avatar-background.pipe';
import { UserInitialsPipe } from '@ezteach/shared/pipes/user-initials.pipe';
import { ET_IS_APPLE_MOBILE } from '@ezteach/shared/tokens/is-mobile-apple';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription, forkJoin, fromEvent, of } from 'rxjs';
import { auditTime, catchError, finalize, first, map, switchMap, tap } from 'rxjs/operators';

export interface IMessage {
  id: Guid;
  idMsg: number;
  authorId: number;
  authorAvatar: string;
  authorInitials: string;
  authorName: string;
  content: string;
  contentSafe: SafeHtml;
  time: string;
  date: string;
  isSelf: boolean;
  isTyping: boolean;
  typingUsers: any[];
  attachments?: null | Array<ChatMessageAttachment>;
}

interface ITimer {
  memberId: number;
  timeoutFn: NodeJS.Timeout;
}

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-chat-merge',
  templateUrl: './group-lesson-chat.component.html',
  styleUrls: ['./group-lesson-chat.component.scss'],
  providers: [UserAvatarBackgroundPipe, UserInitialsPipe, DatePipe],
})
export class GroupLessonChatComponent implements OnInit, AfterViewInit {
  newMessageText = '';
  messageList$: BehaviorSubject<IMessage[]> = new BehaviorSubject([]);
  unreadMessageList$: BehaviorSubject<IMessage[]> = new BehaviorSubject([]);
  chatMembers: ChatLessonMember[] = [];
  typingUsers: any[] = [];
  messageDates: string[] = [];
  typingSender$: Subject<any> = new Subject<any>();
  typingTimers: ITimer[] = [];
  typingMessage: IMessage;
  pageNumber = 1;
  filesToUpload: File[] = [];
  isSending = false;
  currentDate: string = null;
  pageIsVisible = false;
  private lastMessagePointed = true;
  private unreadMessages: any;

  private updatedUnreadMessages: IMessage[];

  readonly pageSize: number = 10;

  updateTopOfMessageListSubs: Subscription;
  waitDividerTimeout: NodeJS.Timeout;
  unreadMessageTimeout: NodeJS.Timeout;

  textAreaIsFocused: boolean = false;
  currentUserId;

  @Output() onMessagesChange = new EventEmitter();
  @Output() onUnreadMessagesChange = new EventEmitter();

  @Input() chatRoomId: number;
  @Input() roomIdChangeTrigger?: boolean;
  @Input() isMobile: boolean;
  @Input() chatIsOpen: boolean = false;
  @Input() isGroupLessonChat: boolean = true;
  @Input() orientationLandscape: boolean;
  @ViewChild('messageListWrapper') messageListWrapper: ElementRef;
  @ViewChild('chatWrapper') chatWrapper: ElementRef;
  @ViewChild('textAreaInput') textAreaInput: ElementRef<HTMLTextAreaElement>;
  @ViewChild('footerWrapper') footerWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('footerInner') footerInner: ElementRef<HTMLDivElement>;

  constructor(
    private chatService: ChatService,
    public groupLessonService: GroupLessonService,
    private signalrChatService: SignalrChatService,
    private readonly userAvatarPipe: UserAvatarBackgroundPipe,
    private readonly userInitialsPipe: UserInitialsPipe,
    private pageVisibilityService: PageVisibilityService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private readonly signalrService: SignalrService,
    @Inject(ET_IS_APPLE_MOBILE) private readonly isIOS: boolean,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.setChatMembers();
    this.currentUserId = this.userService.userData$.value.id;

    // подписка для отслеживания активна ли вкладка браузера у пользователя
    this.pageVisibilityService.getVisibilityState().subscribe(isVisible => {
      this.pageIsVisible = isVisible;

      if (isVisible && this.unreadMessages?.length) {
        this.unreadMessageTimeout = setTimeout(() => {
          console.log('this.unreadMessages ', this.unreadMessages)
          this.setReadChatMessages(this.unreadMessages.map(x => x.id));

          this.updatedUnreadMessages = this.updatedUnreadMessages.filter(
            (_m: IMessage) => !this.messageList$.value?.some((_msg: IMessage) => _msg.idMsg === _m.idMsg)
          );

          if (this.updatedUnreadMessages?.length) {
            this.messageList$.next(this.messageList$.value.concat(this.updatedUnreadMessages));
          }

          this.scrollToLastMessage();

          this.unreadMessages = [];
          this.unreadMessageList$.next([])
          clearTimeout(this.unreadMessageTimeout);
        }, 5000);
      }
    });

    const updateTopOfMessageListSubs = this.updateTopOfMessageList()
      .subscribe(() => {
        this.scrollToLastMessage();
        updateTopOfMessageListSubs.unsubscribe();
      });

    this.signalrChatService.onUserMessageTyping.subscribe(typingObj => {
      if (
        this.chatMembers.find(member => member.memberId === typingObj.memberId) &&
        this.groupLessonService.memberId !== typingObj.memberId &&
        !this.typingUsers.find(member => member.memberId === typingObj.memberId)
      ) {
        const typingUser = this.chatMembers.find(member => member.memberId === typingObj.memberId) as any;
        typingUser.authorId = typingUser?.id;
        typingUser.authorAvatar = this.userAvatarPipe.transform(typingUser);
        typingUser.authorInitials = this.userInitialsPipe.transform(typingUser);
        this.typingUsers.push(typingUser);
        this.typingUsers = [...this.typingUsers];

        if (!this.typingMessage) {
          this.typingMessage = this.addTypingMessage(typingUser);
        }

        this.checkTimers(typingObj.memberId);

        const typingTimer = {} as ITimer;
        typingTimer.memberId = typingObj.memberId;
        typingTimer.timeoutFn = setTimeout(() => {
          this.clearTypingUser(this.typingMessage, typingObj.memberId);
          if (this.typingUsers.length === 0) {
            const previousMessageList = this.messageList$.value.filter(x => !x.isTyping);

            this.messageList$.next([...previousMessageList]);
            this.typingMessage = null;
          }
        }, 5000);
        this.typingTimers.push(typingTimer);
      }
    });

    // это сигнал для остальных участников чата которые подключены к нему, что бы сообщение увидеть без перезагрузки страницы
    this.signalrChatService.onUserMessageSent.pipe(untilDestroyed(this)).subscribe(chatMessageObj => {
      // это нужно потому что компонент используется в разных местах, в груповом звонке нужен groupLessonService.memberId
      // на странице messages приходит sentByUser а не sentByMember
      const currentMemberId = this.groupLessonService.memberId || this.currentUserId;
      const chatMessageMemberId = chatMessageObj.chatMessage.sentByMember?.memberId || chatMessageObj.chatMessage.sentByUser?.id;

      if (currentMemberId !== chatMessageMemberId) {
        this.checkTimers(currentMemberId);

        // если пришло сообщение которое печатал пользователь, убрать его из списка печатающих
        const newMgs = this.messageList$.value?.map((_v: IMessage) => {
          if (_v.isTyping && _v.typingUsers?.length) {
            _v.typingUsers = _v.typingUsers.filter(v => v.memberId !== chatMessageMemberId);
          }
          return _v;
        });

        console.log('this.typingTimers ', this.typingTimers);

        // если список печатающих пуст, то убрать сообщение помечанное как isTyping
        this.messageList$.next(newMgs.filter((_v: IMessage) => !_v.isTyping || _v.isTyping && _v.typingUsers.length));

        this.setMessages();
      }
    });

    this.typingSender$
      .pipe(
        auditTime(1000),
        switchMap(() =>
          this.chatService.apiV1ChatMessagesTypingPatch({
            chatRoomId: this.chatRoomId,
          }),
        ),
      )
      .subscribe();

    this.messageList$
      .pipe(
        untilDestroyed(this),
        tap((messagesList: IMessage[]) => {
          if (messagesList?.length) {
            this.currentDate = messagesList[messagesList.length - 1].date;

            // при каждом изменении обрабатываю текст сообщения
            this.messageList$?.value?.forEach((_m: IMessage) => {
              _m.contentSafe = this.sanitizeMessage(_m.content);
            });
          }
          this.onMessagesChange.emit(messagesList)
        }),
      )
      .subscribe();
    this.unreadMessageList$
      .pipe(
        untilDestroyed(this),
        tap((unreadMessagesList: IMessage[]) => {
          console.log('unreadMessagesList ', unreadMessagesList)
          this.onUnreadMessagesChange.emit(unreadMessagesList)
        }),
      )
      .subscribe();

    this.signalrService.OnChangeUserAvatar
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (!data?.userId?.toString()?.length) {
          return;
        }

        if (!!this.messageList$?.value?.find(message => message.authorId === data.userId)) {
          const messageList = this.messageList$?.value?.map(message => {
            if (message.authorId === data.userId) {
              const messageCopy = { ...message };
              messageCopy.authorAvatar = this.userAvatarPipe.transform(data);
              return messageCopy;
            };
            return message;
          });
          this.messageList$.next(messageList);
        }

        if (!!this.unreadMessageList$?.value?.find(message => message.authorId === data.userId)) {
          const unreadMessageList = this.unreadMessageList$?.value?.map(message => {
            if (message.authorId === data.userId) {
              const messageCopy = { ...message };
              messageCopy.authorAvatar = this.userAvatarPipe.transform(data);
              return messageCopy;
            };
            return message;
          });
          this.unreadMessageList$.next(unreadMessageList);
        }
      });

    this.signalrService.OnChangeUserProfile
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (!data?.userId?.toString()?.length) {
          return;
        }

        if (!!this.messageList$?.value?.find(message => message.authorId === data.userId)) {
          const messageList = this.messageList$?.value?.map(message => {
            if (message.authorId === data.userId) {
              return { ...message, authorName: data?.name };
            };
            return message;
          });
          this.messageList$.next(messageList);
        }

        if (!!this.unreadMessageList$?.value?.find(message => message.authorId === data.userId)) {
          const unreadMessageList = this.unreadMessageList$?.value?.map(message => {
            if (message.authorId === data.userId) {
              return { ...message, authorName: data?.name };
            };
            return message;
          });
          this.unreadMessageList$.next(unreadMessageList);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roomIdChangeTrigger && !changes.roomIdChangeTrigger.isFirstChange()) {
      this.scrollToLastMessage();
    }

    if (changes.chatIsOpen) {
      // отслеживание когда чат открыт то для мобильного, убираем скрол с body и скролим к ласт сообщению
      const _body = document.getElementsByTagName('body')[0];
      if (changes.chatIsOpen.currentValue) {
        const footerHeight = this.footerWrapper?.nativeElement.offsetHeight;

        if (footerHeight) {
          document.documentElement.style.setProperty('--group-lesson-chat-footer-height', `${footerHeight}px`);
          this.cdr.detectChanges();
        }


        if (this.textAreaInput) {
          this.textAreaInput.nativeElement.value = '';
        }

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        this.scrollToLastMessage();
      }

      if (this.isMobile && _body) {
        if (changes.chatIsOpen.currentValue) {
          _body.classList.add('is-overflow-mobile')
        } else {
          if (this.messageListWrapper?.nativeElement) {
            this.messageListWrapper.nativeElement.style.marginTop = '0px';
          }
          _body.classList.remove('is-overflow-mobile');
        }
      }
    }
  }

  checkTimers(memberId: number) {
    // пока закомментировал потому, не знаю зачем этот фильтр, он убирает из печатающих пользователей
    // this.typingTimers = this.typingTimers.filter(timer => timer.memberId !== memberId);
    clearTimeout(this.typingTimers.find(timer => timer.memberId === memberId)?.timeoutFn);
    if (this.typingTimers.find(timer => timer.memberId === memberId)) {
      this.clearTypingUser(this.typingMessage, memberId);
    }
  }

  clearTypingUser(typingMessage: IMessage, memberId: number) {
    if (memberId) {
      this.typingUsers = typingMessage.typingUsers?.filter(member => member.memberId !== memberId);
      if (this.typingUsers.length === 0) {
        return;
      }
      typingMessage.typingUsers = this.typingUsers;
      const messageIndex = this.messageList$.value.findIndex(message => message.id === typingMessage.id);
      this.messageList$.value[messageIndex] = typingMessage;
    }
  }

  adjustHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;

    const lineHeight = 18; // высота одной строки в пикселях
    const maxLines = 11; // максимальное количество строк

    // сброс высоты для корректного пересчёта
    textarea.style.height = 'auto';

    // Вычисляем текущую высоту текста
    const scrollHeight = textarea.scrollHeight;
    const maxHeight = lineHeight * maxLines; // максимальная высота для 7 строк

    if (scrollHeight > maxHeight) {
      // ограничиваем высоту
      textarea.style.height = `${maxHeight}px`;
    } else if (scrollHeight > 40) {
      // устанавливаем высоту текста, если больше минимальной
      textarea.style.height = `${scrollHeight}px`;

      this.scrollToLastMessage();
    } else {
      // сбрасываем на минимальную высоту
      textarea.style.height = null;
      document.documentElement.style.setProperty('--group-lesson-chat-footer-height', `50px`);
    }

  }

  updateFooterHeight() {
    const footerHeight = this.footerWrapper?.nativeElement.clientHeight;

    if (footerHeight) {
      document.documentElement.style.setProperty('--group-lesson-chat-footer-height', `${footerHeight}px`);

      this.cdr.detectChanges();
    }

  }

  ngAfterViewInit() {
    if (this.textAreaInput?.nativeElement && this.footerWrapper?.nativeElement) {
      fromEvent(this.textAreaInput.nativeElement, 'input')
        .pipe(
          untilDestroyed(this),
          tap(event => {
            this.updateFooterHeight();
          })
        )
        .subscribe();
    }

    fromEvent(this.messageListWrapper.nativeElement, 'scroll')
      .pipe(
        untilDestroyed(this),
        tap(_ => (this.lastMessagePointed = this.pointedAtLastMessage())),
      )
      .subscribe();


    this.setChatMembers();
  }

  scrollToLastMessage() {
    setTimeout(() => {
      this.messageListWrapper?.nativeElement.scrollTo(0, this.messageListWrapper.nativeElement.scrollHeight);
    }, 100);
  }

  pointedAtLastMessage(): boolean {
    const element = this.messageListWrapper.nativeElement;
    const acceptableInaccuracy = 20;
    const scrollDifference = element.scrollHeight - (element.clientHeight + Math.round(element.scrollTop));
    return scrollDifference < acceptableInaccuracy;
  }

  addTypingMessage(typingUser: ChatLessonMember): IMessage {
    const typingMessage = {} as IMessage;
    typingMessage.id = Guid.create();
    typingMessage.isSelf = false;
    typingMessage.isTyping = true;
    if (this.typingUsers.length === 1) {
      typingMessage.authorName = typingUser.name;
    } else if (this.typingUsers.length > 1) {
      typingMessage.authorName = this.typingUsers.map(member => member.name).join(', ');
    }
    typingMessage.content = '';
    typingMessage.time = moment(new Date()).format('HH:mm');
    typingMessage.date = !this.messageDates.find(date => date === moment(new Date()).format('DD MMM'))
      ? moment(new Date()).format('DD MMM')
      : '';
    if (!this.messageDates.find(date => date === typingMessage.date)) {
      this.messageDates.push(typingMessage.date);
    }
    typingMessage.typingUsers = this.typingUsers;
    const previousMessagesList = this.messageList$.value;
    this.messageList$.next([...previousMessagesList, typingMessage]);
    if (this.lastMessagePointed) {
      this.scrollToLastMessage();
    }
    return typingMessage;
  }

  setChatMembers() {
    this.chatService
      .apiV1ChatRoomByIdGet({
        chatRoomId: this.chatRoomId,
      })
      .pipe(
        tap(chatRoom => {
          if (chatRoom.succeeded && chatRoom.data && chatRoom.data.chatRoomUsers) {
            this.chatMembers = chatRoom.data.chatRoomUsers;
          }
        }),
      )
      .subscribe();
  }

  getUpdatedMessage(sourceMessage: ChatMessage): IMessage {
    const fieldName: string = this.isGroupLessonChat ? 'sentByMember' : 'sentByUser';

    this.messageList$.next([...new Map(this.messageList$.value.map(item =>
      [item.id, item])).values()]);
    const message = {} as IMessage;
    message.id = Guid.create();

    if (this.isGroupLessonChat) {
      message.isSelf = sourceMessage.sentByMember.memberId === this.groupLessonService.memberId;
    } else {
      message.isSelf = sourceMessage.sentByUser.id === this.userService.userData$.value.id;
    }

    message.idMsg = sourceMessage.id;

    message.authorId = fieldName === 'sentByUser' ? sourceMessage?.[fieldName]?.id : sourceMessage?.[fieldName]?.user?.id;
    message.authorAvatar = this.userAvatarPipe.transform(sourceMessage[fieldName]);
    message.authorInitials = this.userInitialsPipe.transform(sourceMessage[fieldName]);
    message.authorName = sourceMessage[fieldName].name;
    message.content = sourceMessage.messageText;
    message.time = moment(Date.parse(sourceMessage.publicationDate)).format('HH:mm');
    message.date = !this.messageDates.find(
      date => date === moment(Date.parse(sourceMessage.publicationDate)).format('DD MMM'),
    )
      ? moment(Date.parse(sourceMessage.publicationDate)).format('DD MMM')
      : '';
    if (!this.messageDates.find(date => date === message.date)) {
      this.messageDates.push(message.date);
    }
    message.attachments = [...sourceMessage.attachments];
    return message;

  }

  setReadChatMessages(unreadMessagesIds: number[]) {
    this.chatService
      .apiV1ChatMessagesSetreadPatch({ body: unreadMessagesIds })
      .pipe(untilDestroyed(this), first())
      .subscribe(resp => {
        this.unreadMessageList$.next([]);
      });
  }

  updateTopOfMessageList() {

    const allMessages = this.chatService
      .apiV1ChatChatRoomIdMessagesGet({
        chatRoomId: this.chatRoomId,
        PageSize: this.pageSize,
        PageNumber: this.pageNumber,
        DescendingOrder: true,
      })
      .pipe(
        map(chatMessageResponse => {
          if (chatMessageResponse.succeeded && chatMessageResponse.data) {
            return chatMessageResponse.data;
          }
          return [];
        }),
      );

    const unreadMessages = this.chatService
      .apiV1ChatChatRoomIdMessagesUnreadGet({
        chatRoomId: this.chatRoomId
      })
      .pipe(
        map(chatMessageResponse => {
          if (chatMessageResponse.succeeded && chatMessageResponse.data) {
            return chatMessageResponse.data?.unreadMessages ?? [];
          }
          return [];
        }),
      );

    return forkJoin([allMessages, unreadMessages]).pipe(
      untilDestroyed(this),
      tap(([allMessages, unreadMessages]) => {
        this.unreadMessages = unreadMessages;
        this.updatedUnreadMessages = unreadMessages.map(chatMessage => this.getUpdatedMessage(chatMessage));

        let filteredMessages = (unreadMessages.length > 0 ? allMessages.filter(message => !unreadMessages.find(unreadMessage => unreadMessage.id === message.id)) : allMessages)
          .map(chatMessage => this.getUpdatedMessage(chatMessage)).reverse();

        let previousMessageList = [];
        if (this.typingMessage) {
          previousMessageList = this.messageList$.value.filter(chatMessage => chatMessage.id !== this.typingMessage.id);
        } else {
          previousMessageList = this.messageList$.value;
        }
        filteredMessages = filteredMessages.filter(message => !previousMessageList.find(prevMessage => prevMessage.id === message.id));

        if (unreadMessages.length > 0) {
          if (this.pageIsVisible) {
            // если вкладка активна, сразу помечаем сообщения как прочитанные
            this.setReadChatMessages(unreadMessages.map(x => x.id));
            this.messageList$.next(this.messageList$.value.concat(this.updatedUnreadMessages));
            this.scrollToLastMessage();
          } else {
            if (this.messageList$.value.length === 0) {
              this.messageList$.next(filteredMessages.concat(previousMessageList));
            }

            this.typingMessage ?
              this.unreadMessageList$.next(this.updatedUnreadMessages.concat([this.typingMessage])) :
              this.unreadMessageList$.next([...this.updatedUnreadMessages]);

            clearTimeout(this.waitDividerTimeout);
            clearTimeout(this.unreadMessageTimeout);

            this.waitDividerTimeout = setTimeout(() => {
              this.messageListWrapper.nativeElement
                .scrollTop = (this.documentRef.querySelector('.new-messages-divider') as HTMLDivElement).offsetTop;
              clearTimeout(this.waitDividerTimeout);
            }, 200);
          }
        } else {
          this.messageList$.next(filteredMessages.concat(previousMessageList));
        }
      })
    );
  }


  setMessages() {
    if (this.updateTopOfMessageListSubs) {
      this.updateTopOfMessageListSubs.unsubscribe();
    }
    this.updateTopOfMessageListSubs = this.updateTopOfMessageList().subscribe();
  }

  onScrollMessageListUp() {
    // предотвращает вызов api если в последнем response было неполное количество сообщений
    if (this.messageList$.value.length < this.pageNumber * this.pageSize) {
      return;
    }
    this.pageNumber++;
    this.setMessages();
  }

  close() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        chat: false,
      },
      queryParamsHandling: 'merge',
    });
  }

  sanitizeMessage(message: string): SafeHtml {
    const formattedMessage = this.linkify(message.replace(/\n/g, '<br>'));
    return this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
  }

  linkify(message: string): string {
    // замена ссылок на кликабельные
    return message.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" class="chat-link" target="_blank">$1</a>');
  }

  type() {
    if (this.isGroupLessonChat) {
      this.typingSender$.next();
    }

  }

  // убирает баг на мобильных устройствах когда после открытия клавиатуры
  // появляется отступ примерно в 82px я его вычисляю marginTop
  // Только для ios
  fixMobileMargin(event: Event, isFocused: boolean) {
    if (this.isMobile && this.isIOS) {
      this.textAreaIsFocused = isFocused;
      const marginTop: number = document.body.scrollHeight - document.body.clientHeight;

      if (isFocused) {
        this.messageListWrapper.nativeElement.style.marginTop = marginTop + 'px';
      } else {
        this.messageListWrapper.nativeElement.style.marginTop = '0px';

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }

    }
  }

  onKeyDown(event: Event) {
    const keyboardEvent = event as KeyboardEvent;

    if (!this.isMobile) {
      if (keyboardEvent.shiftKey) {
        return;
      }

      keyboardEvent.preventDefault();

      this.send(event);
    }
  }

  send(event: Event) {
    const _msgText = this.newMessageText.trim();

    if (this.isSending) {
      return;
    }

    if (!_msgText && !this.filesToUpload?.length || this.newMessageText.length > 1024) {
      return; // Не отправлять, если сообщение пустое или уже идет отправка
    }

    this.newMessageText = '';


    if (this.textAreaInput?.nativeElement) {
      document.documentElement.style.setProperty('--group-lesson-chat-footer-height', `50px`);
      this.textAreaInput.nativeElement.style.height = null;
    }

    this.updateFooterHeight();

    this.isSending = true;
    this.chatService
      .apiV1ChatChatRoomIdMessagesPost({
        chatRoomId: this.chatRoomId,
        body: {
          messageText: _msgText,
          files: this.filesToUpload,
        },
      })
      .pipe(
        tap(chatMessageResponse => {
          if (chatMessageResponse.succeeded && chatMessageResponse.data) {
            this.checkTimers(this.isGroupLessonChat ? this.groupLessonService.memberId : this.currentUserId);
            const previousMessageList = this.messageList$.value.filter(message => !message.isTyping);
            this.messageList$.next([
              ...previousMessageList,
              this.getUpdatedMessage(chatMessageResponse.data as ChatMessage),
            ]);
          }

          this.filesToUpload = [];
        }),
        catchError((e: any) => {
          console.log(e);
          return of(null);
        }),
        finalize(() => {
          this.isSending = false;
          setTimeout(() => {
            this.scrollToLastMessage();
            this.messageListWrapper.nativeElement.style.marginTop = '0px';
            this.cdr.detectChanges();
          }, 0);
        })
      )
      .subscribe();
  }

  getMultiTypingContainerPadding(typingUsersLength: number): string {
    return (typingUsersLength * 4.5 - (typingUsersLength - 1) * 1.5).toString() + 'rem';
  }

  onFileSelect($event) {
    this.filesToUpload.push($event);
  }

  removeFile(index: number) {
    this.filesToUpload.splice(index, 1);
  }

  download($event: ChatMessageAttachment) {
    if ($event) {
      this.chatService
        .apiV1ChatMessagesMessageIdAttachmentsAttachmentUrlsGet({
          messageId: $event.chatMessageId,
          attachmentId: $event.id,
        })
        .pipe(
          tap((x: ChatMessageAttachmentUrls) => {
            window.open(x.fileName, '_blank');
          }),
          catchError(e => {
            console.log(e);
            return of(null);
          }),
        )
        .subscribe();
    }
  }

}

import { Injectable } from '@angular/core';
import { CheduledLessonActivateResponse } from '@ezteach/api/models/waitroom/cheduled-lesson-activate-response';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private hubConnection: HubConnection;
  private connectionUrl = environment.apiUrl + '/hubs/global';

  readonly connectionEstablished = new Subject<boolean>();
  readonly onChatRequestCreated = new Subject<boolean>();
  readonly onChatRequestCanceled = new Subject<any>();
  readonly onChatRequestAccepted = new Subject<any>();
  readonly onPostProcessingCompleted = new Subject<boolean>();
  readonly onLessonStatusChanged = new Subject<boolean>();
  readonly onTutorAvailabilityChanged = new Subject<boolean>();
  readonly onUserNotificationCreated = new Subject<boolean>();
  readonly onUserNotificationUpdated = new Subject<boolean>();
  readonly onScheduledLessonStarted = new Subject<boolean>();
  readonly onILeftLesson = new Subject<any>();
  readonly onIJoinedToLesson = new Subject<any>();
  readonly onScheduledLessonActivated = new Subject<CheduledLessonActivateResponse>();
  readonly onUserLogOut = new Subject<any>();
  readonly OnChangeUserAvatar = new Subject<any>();
  readonly OnJoinMe = new Subject<any>();
  readonly OnChangeUserProfile = new Subject<any>();
  readonly onLessonCanceled = new Subject<any>();

  constructor(private authStorage: OAuthStorage) { }

  public connect = () => {
    this.startConnection();
    this.addListeners();
  };

  private getConnection(): HubConnection {
    return (
      new HubConnectionBuilder()
        .withUrl(this.connectionUrl, {
          accessTokenFactory: () => {
            const token = this.authStorage.getItem('access_token');
            return token;
          },
        })
        .withAutomaticReconnect()
        //  .configureLogging(LogLevel.Trace)
        .build()
    );
  }

  updateNotes() {
    this.onUserNotificationUpdated.next(true);
  }

  private startConnection() {
    this.hubConnection = this.getConnection();

    this.hubConnection
      .start()
      .then(() => {
        this.connectionEstablished.next(true);
      })
      .catch(err => console.log('error while establishing signalr connection: ' + err));
  }

  private addListeners() {
    this.hubConnection.on('onChatRequestCreated', data => {
      this.onChatRequestCreated.next(data);
    });

    this.hubConnection.on('onChatRequestCanceled', data => {
      this.onChatRequestCanceled.next(data);
    });

    this.hubConnection.on('onChatRequestAccepted', data => {
      this.onChatRequestAccepted.next(data);
    });

    this.hubConnection.on('onTutorAvailabilityChanged', data => {
      this.onTutorAvailabilityChanged.next(true);
    });

    this.hubConnection.on('onUserNotificationCreated', data => {
      this.onUserNotificationCreated.next(true);
    });

    this.hubConnection.on('onScheduledLessonStarted', data => {
      this.onScheduledLessonStarted.next(data);
    });
    this.hubConnection.on('onLessonStatusChanged', data => {
      this.onLessonStatusChanged.next(data);
    });
    this.hubConnection.on('onILeftLesson', () => {
      this.onILeftLesson.next();
    });
    this.hubConnection.on('OnIJoinedToLesson', () => {
      this.onIJoinedToLesson.next();
    });
    this.hubConnection.on('onScheduledLessonActivated', x => {
      this.onScheduledLessonActivated.next(x);
    });
    this.hubConnection.on('onUserLogOut', x => {
      this.onUserLogOut.next(x);
    });
    this.hubConnection.on('OnChangeUserAvatar', x => {
      this.OnChangeUserAvatar.next(x);
    });
    this.hubConnection.on('joinme', x => {
      this.OnJoinMe.next(x);
    });
    this.hubConnection.on('OnChangeUserProfile', x => {
      this.OnChangeUserProfile.next(x);
    });
    this.hubConnection.on('onLessonCanceled', x => {
      this.onLessonCanceled.next(x);
    });
  }
}

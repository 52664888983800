import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TooltipPosition } from '@angular/material/tooltip';
import { UserService } from '@ezteach/_services/user.service';
import { CommonValidationStatusEnum, User } from '@ezteach/api/models';
import { HomeWorkTemplate } from '@ezteach/api/models/homework';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import { BlockApiService } from '@ezteach/blocks/services/block-api.service';
import {
  CalendarPopupService,
  CalendarService,
  ICreateMeetingPopupClose,
  ICreateSlotPopupClose,
  IMeetingPopupData,
  MEETING_UI_METADATA,
  PopupMode,
  PopupState,
} from '@ezteach/calendar';
import { ICaledarPopupRef, IDateRange } from '@ezteach/calendar/models';
import {
  AccessToArchiveLesson,
  ChatLessonPrivacy,
  ChatLessonPublisherPolicy,
  SelectedPublishingPolicy,
} from '@ezteach/group-lesson/services/group-lesson.service';
import { HomeworkTemplatePagedApiResponse, HomeworksService } from '@ezteach/homeworks/homeworks.service';
import { ISortParams } from '@ezteach/homeworks/sort-bar/sort-bar.component';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tuiPure } from '@taiga-ui/cdk/decorators';
import * as moment from 'moment';
import { Observable, Subject, combineLatest, fromEvent, merge } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CALENDAR_POPUP_DATA, CALENDAR_POPUP_REF } from '../../constants';
import { SelectParcipantsComponent } from '../select-parcipants/select-parcipants.component';
import { fixFullcalendarBugOffset } from '../utils/calendar.utils';

@UntilDestroy()
@Component({
  templateUrl: './create-meeting-popup.component.html',
  styleUrls: ['./create-meeting-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateMeetingPopupComponent implements OnInit {
  @HostBinding('class.ezteach-calendar-popup') className = true;
  @HostBinding('class.mobile') mobileClass = false;
  @ViewChild(SelectParcipantsComponent) select: SelectParcipantsComponent;
  @ViewChild('homeworkSelectElement') homeworkSelectElement: MatSelect;

  readonly dataUI = MEETING_UI_METADATA;
  readonly link$: Observable<string>;
  readonly inviteLink$: Observable<string>;
  readonly guestInviteLink$: Observable<string>;
  readonly validationStatus: CommonValidationStatusEnum =
    this.userService.userData$.value.tutor.profileWizardValidationStatusId;
  readonly chatLessonPublisherPolicyEnum = ChatLessonPublisherPolicy;
  readonly chatLessonPrivacyEnum = ChatLessonPrivacy;
  readonly accessToArchiveLessonEnum = AccessToArchiveLesson;
  readonly currentPublishingPolicy: SelectedPublishingPolicy;
  readonly tooltipPosition: TooltipPosition = 'after';
  readonly isEditMode: boolean;
  readonly onlyGroupLessonTab$ = this.calendarPopupService.onlyGroupLessonTab$;
  readonly showSelectLessonTab$ = this.calendarPopupService.showSelectLessonTab$;
  newDataRange: IDateRange = { start: new Date(), end: new Date() };
  warningTimeMsg: string | null = null;
  warningIntervalMsg: string | null = null;
  warningPrivateMeetingMsg: string | null = null;
  warningApiMsg: string | null = null;
  meetingIsCreated = false;
  slotIsCreated: boolean;
  isGroupLesson: boolean;
  cancelPopup=false;//Всплывающая форма удаления повторяющегося занятия
  deleteAll=1;//Удалить все повторяющиеся события или только это
  loading = false;
  isMobile: boolean;
  checked = false;
  link = '';
  users: User[] = [];
  selectedHomeworkId: number | null = 0;
  IntervalId: number | null = 0;
  selectedHomeworkName: string;
  repeatCheckNotified: boolean=false;//Пользователь уведомлен об ошибках выбранного интервала
  selectedIntervalName: string;
  popupStateType = PopupState;
  popupState: PopupState = this.popupStateType.groupLesson;
  private initialData: string;
  groupLessonId: number | null = null;
  lessonSubject: string | null = null;
  historyLessons = [];

  get dateRange$() {
    return this.data.meetingTimeChange$;
  }

  get isApproved() {
    return this.validationStatus === CommonValidationStatusEnum.Approved;
  }

  get isHaveSpecialization() {
    return this.data.groupLessonOptions.disciplines.length !== 0;
  }

  get isAccessToArchiveLessonForAll() {
    return this.currentPublishingPolicy.accessToArchiveLesson === AccessToArchiveLesson.All;
  }

  get isRecord() {
    return this.currentPublishingPolicy.lessonWillbeRecorded;
  }

  private get updatePosition$() {
    return this.data.updatePosition$;
  }

  private homeworkSelectDestroyed$ = new Subject<boolean>();

  get canEditTime() {
    return this.data.canEditTime;
  }

  get currentMeetings() {
    return this.data.currentMeetings;
  }

  public get disciplines() {
    return this.data.groupLessonOptions.disciplines;
  }

  public get currentDiscipline(): string {
    return this.disciplines.find(d => d.id === this.data.groupLessonOptions.selectedDisciplineId).name;
  }

  public get subject() {
    return this.data.groupLessonOptions.subject;
  }

  homeworks: HomeWorkTemplate[] = [];
  intervals: any=[];
  take = 100;
  totalRecords = 0;
  currentPage = 1;
  load$ = new Subject<{
    disciplineId: number;
    pageNumber: number;
  }>();
  isLoadedHomeworkPage: boolean = false;
  isLoadedIntervals: boolean = false;

  sortParam: ISortParams | null = null;
  isLoadedSelectedHomework: boolean = true;
  
  membersDefaultValue = this.data?.groupLessonOptions?.invitedUsers;

  private homeworkPageIndex: number = 1;

  onClosedMembersSelectBound: (() => void);

  constructor(
    @Inject(CALENDAR_POPUP_REF)
    private readonly popupRef: ICaledarPopupRef,
    @Inject(CALENDAR_POPUP_DATA)
    public readonly data: IMeetingPopupData,
    private _cdr: ChangeDetectorRef,
    private calendarPopupService: CalendarPopupService,
    private calendarService: CalendarService,
    private breakPointObserver: BreakpointObserver,
    private translocoService: TranslocoService,
    private userService: UserService,
    private homeworkService: HomeworksService,
    private blockApiService: BlockApiService,
  ) {
    this.isEditMode = this.data.popupMode === PopupMode.Edit;
    this.popupState = this.data.popupState;
    this.currentPublishingPolicy = this.data.groupLessonOptions.selectedPublishingPolicy;
    this.currentPublishingPolicy = { ...this.data.groupLessonOptions.selectedPublishingPolicy };
    if (this.data.groupLessonOptions.lessonId){
      this.currentPublishingPolicy.lessonWillbeRecorded=this.data.groupLessonOptions.lessonWillBeRecorded;
    }
    if (this.data.groupLessonOptions.intervalId) {//Если intervalId заполнен, подтягиваем значение
      this.IntervalId=this.data.groupLessonOptions.intervalId; 
    }
    
    

    const joinIdentity$ = this.calendarPopupService.joinIdentityCreatedLesson$.pipe(filter(v => !!v));

    const inviteIdentity$ = this.calendarPopupService.inviteIdentityCreatedLesson$.pipe(filter(v => !!v));

    this.link$ = joinIdentity$.pipe(map(id => `${window.location.origin}/wait?id=${id}`));

    this.inviteLink$ = combineLatest([joinIdentity$, inviteIdentity$]).pipe(
      map(([id, invite]) => `${window.location.origin}/wait?id=${id}&invite=${invite}`),
      shareReplay(1),
    );

    this.guestInviteLink$ = combineLatest([joinIdentity$, inviteIdentity$]).pipe(
      map(([id, invite]) => `${window.location.origin}/wait?id=${id}&guestInvite=${invite}`),
      shareReplay(1),
    );
  }

  ngOnInit(): void {
    this.selectedHomeworkId = !!this.data.groupLessonOptions.homeWorkTemplateId
      ? this.data.groupLessonOptions.homeWorkTemplateId
      : 0;
    this.selectedHomeworkName = this.data.groupLessonOptions.homeWorkTemplateName ?? this.translocoService.translate("Не указано");
    
    this.isLoadedSelectedHomework = !this.selectedHomeworkId?.toString()?.length;

    this.onClosedMembersSelectBound = this.onClosedMembersSelect.bind(this);

    this.load$
      .pipe(
        tap(_ => this.isLoadedHomeworkPage = false),
        untilDestroyed(this),
        switchMap(value => this.homeworkService.GetHomeworkTemplateByDisciplineId(this.configureRequestData(value.disciplineId, value.pageNumber))),
      )
      .subscribe(x => {
        this.getDataSource(x);
      });

    this.loadHomeworkTemplates(this.data?.groupLessonOptions?.selectedDisciplineId, this.homeworkPageIndex);

    this.calendarService.apiV1CLessonIntervalsGet().subscribe((response:any)=> {
      var days=["Sunday","Monday", "Tuesday","Wednesday","Thursday","Friday","Saturday"];//Дни недели
      this.intervals = response.data
        .filter(i=>(i.type=="EveryNumberDay" && i.day==this.newDataRange.start.getDate()) || (
          i.type=="EveryWeekDay" && (i.dayWeek==days[this.newDataRange.start.getDay()] || i.dayWeek==null)
        ) || (i.dayWeek==null && i.day==null));
      if (this.data.groupLessonOptions.intervalId) {//Если intervalId заполнен, подтягиваем значение
        this.IntervalId=this.data.groupLessonOptions.intervalId;
        this.selectedIntervalName = this.intervals?.find(h => h.sortIndex === this.IntervalId)?.name;
        this.repeatCheckNotified=true;       
      }
      this.isLoadedIntervals=true; 
      this._cdr.detectChanges();
    });

    this.checked = this.meetingIsCreated;
    /* Update position on time slot changes */
    merge(this.dateRange$, this.updatePosition$)
      .pipe(
        tap(() => {
          this.calendarPopupService.eventSuccessfullyCreated$.next(false);
          this.popupRef.updatePosition();
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.calendarPopupService.eventSuccessfullyCreated$
      .pipe(
        tap(status => {
          this.slotIsCreated = status;
          this._cdr.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe();

    if (this.data.groupLessonOptions.meetingIsCreated) {
      this.meetingIsCreated = true;
      this.loading = false;
    }

    this.dateRange$.pipe(untilDestroyed(this)).subscribe(resp => {
      this.newDataRange.start = resp.start;
      this.newDataRange.end = resp.end;
      if (resp?.start?.getHours() == 0 && resp?.end?.getHours() == 0) {
        this.newDataRange.end.setDate(this.newDataRange.end.getDate() - 1);
      }
      this._cdr.detectChanges();
    });

    this.calendarPopupService.error$
      .pipe(
        filter(v => !!v),
        untilDestroyed(this),
      )
      .subscribe(message => {
        this.warningTimeMsg = this.translocoService.translate(message);
        this._cdr.detectChanges();
      });

    this.calendarPopupService.errorAPI$
      .pipe(
        filter(v => !!v?.length),
        untilDestroyed(this),
      )
      .subscribe(message => {
        this.warningApiMsg = this.translocoService.translate(message ?? this.dataUI.commonApiErrorText);
        this._cdr.detectChanges();
      });

    this.calendarPopupService.isGroupLesson$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isGroupLesson = value;
      this.calendarPopupService.overlayReposition();
      this._cdr.detectChanges();
    });

    this.calendarPopupService.joinIdentityCreatedLesson$
      .pipe(
        filter(v => !!v),
        map(joinIdentity => window.location.origin + '/wait?id=' + joinIdentity),
        tap(link => {
          this.link = link;
          this._cdr.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.calendarPopupService.groupLessonId$
      .pipe(
        filter(v => !!v),
        tap(x => {
          this.groupLessonId = x;
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.calendarPopupService.lessonSubject$
      .pipe(
        filter(v => !!v),
        tap(x => {
          this.lessonSubject = x;
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.breakPointObserver
      .observe('(max-width: 1279.9px)')
      .pipe(untilDestroyed(this))
      .subscribe(({ matches }) => {
        this.isMobile = matches;
        this.mobileClass = matches;
      });

    this.initialData = JSON.stringify(this.prepareOptions());
  }

  ngOnDestroy() {
    this.onDestroyHomeworkSelect();
  }

  configureRequestData(disciplineId: number, pageNumber: number,) {
    let requestData = {
      PageNumber: pageNumber,
      PageSize: this.take,
      SortByDesc: 'id',
      DisciplineId: disciplineId,
    };
    if (this.sortParam) {
      requestData = { ...requestData, ...this.sortParam };
    }

    return requestData;
  }

  loadHomeworkTemplates(loadDisciplineId: number, loadPageNumber: number) {
    if (!!loadDisciplineId) {
      this.load$.next({
        disciplineId: loadDisciplineId,
        pageNumber: loadPageNumber
      });
    } else {
      this.homeworks = [];
      this.isLoadedHomeworkPage = true;
    }
  }

  getDataSource(response: StrictHttpResponse<HomeworkTemplatePagedApiResponse>) {
    this.totalRecords = response?.body?.data ? response?.body?.data?.totalRecords : 0;

    this.isLoadedHomeworkPage = true;

    if (this.totalRecords === 0) {
      this.homeworks = [];
      this._cdr.detectChanges();
      return;
    }

    if (this.totalRecords === this.homeworks?.length) {
      return;
    }

    if (!this.isLoadedSelectedHomework &&
      !!response?.body?.data?.records?.length &&
      !!response?.body?.data?.records.find(h => h.id === this.selectedHomeworkId)
    ) {
      this.isLoadedSelectedHomework = true;
    }

    this.homeworks = this.homeworks.concat(response?.body?.data?.records);
    this._cdr.detectChanges();
  }

  @tuiPure
  getTitleText(meetingIsCreated: boolean, slotCreated: boolean, isGroupLesson: boolean, isEditMode: boolean): string {
    if (isEditMode) {
      return 'Редактирование занятия';
    }
    if (!meetingIsCreated && !!!slotCreated) {
      return 'Новое событие';
    }
    if (!meetingIsCreated && slotCreated && !isGroupLesson) {
      return 'Свободный слот создан';
    }
    if (!meetingIsCreated && slotCreated && isGroupLesson) {
      return 'Занятие создано';
    }
    return '';
  }

  dateStartChanged(date: Date): void {
    this.newDataRange = { start: date, end: this.newDataRange.end };
    this.calendarService.apiV1CLessonIntervalsGet().subscribe((response:any)=> {
      var days=["Sunday","Monday", "Tuesday","Wednesday","Thursday","Friday","Saturday"];//Дни недели
      this.intervals = response.data
      .filter(i=>(i.type=="EveryNumberDay" && i.day==this.newDataRange.start.getDate()) || (
        i.type=="EveryWeekDay" && (i.dayWeek==days[this.newDataRange.start.getDay()] || i.dayWeek==null)
      ) || (i.dayWeek==null && i.day==null))
      if (!this.intervals?.find(h => h.sortIndex === this.IntervalId)){//Если новая дата не попадает в интервал, сбрасываем его
        this.IntervalId=0;
        this.selectedIntervalName = this.translocoService.translate("Не повторять");  
      }
      this.isLoadedIntervals=true; 
      this._cdr.detectChanges();
    });
    this.calendarPopupService.error$.next('');
    this.warningTimeMsg = this.getMeetingTimeValidationError(this.newDataRange);
    this.repeatCheckNotified=false;//Если изменилось, сбрасываем флаг проверки
  }

  dateEndChanged(date: Date): void {
    this.newDataRange = { start: this.newDataRange.start, end: date };
    this.calendarService.apiV1CLessonIntervalsGet().subscribe((response:any)=> {
      var days=["Sunday","Monday", "Tuesday","Wednesday","Thursday","Friday","Saturday"];//Дни недели
      this.intervals = response.data
      .filter(i=>(i.type=="EveryNumberDay" && i.day==this.newDataRange.start.getDate()) || (
        i.type=="EveryWeekDay" && (i.dayWeek==days[this.newDataRange.start.getDay()] || i.dayWeek==null)
      ) || (i.dayWeek==null && i.day==null))
        if (!this.intervals?.find(h => h.sortIndex === this.IntervalId)){//Если новая дата не попадает в интервал, сбрасываем его
          this.IntervalId=0;
          this.selectedIntervalName = this.translocoService.translate("Не повторять");  
        }
      this.isLoadedIntervals=true; 
      this._cdr.detectChanges();
    });
    this.calendarPopupService.error$.next('');
    this.warningTimeMsg = this.getMeetingTimeValidationError(this.newDataRange);
    this.repeatCheckNotified=false;//Если изменилось, сбрасываем флаг проверки
  }

  save(): void {
    if (this.validateMeetingTime() && this.validatePrivateMeeting()) {
      if (this.isGroupLesson) {
        this.loading = true;
      }
      if (this.repeatCheckNotified  || this.IntervalId==null || this.IntervalId==0){
        this.popupRef.receiveMeetingData(this.prepareOptions() as ICreateMeetingPopupClose);//Если пользователь уведомлен, то сохраняем
      }
      else {
        this.calendarService.ApiV1CalendarGroupValidateIntervalPost(
          {
            IntervalId: this.IntervalId!=0? this.IntervalId: null,
            lessonDate: moment.utc(this.newDataRange.start).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            LessonStartTime: moment(this.newDataRange.start).utc().format('HH:mm'),
            LessonEndTime: moment(this.newDataRange.end).utc().format('HH:mm'),
            InvitedUsers: this.select?.items?.map(x => x.id)
          }
        ).subscribe(res=> {
          if (res?.body?.data?.occupiedDates && res?.body?.data?.occupiedDates.length>0){
            this.warningIntervalMsg=this.translocoService.translate("У вас уже заняты слоты")+": " +res?.body?.data?.occupiedDates.map((d:string)=>moment(new Date(d)).utc().format('DD.MM.YYYY')).join("; ");
          }
          else{
            this.repeatCheckNotified=true;
            this._cdr.detectChanges();
            this.popupRef.receiveMeetingData(this.prepareOptions() as ICreateMeetingPopupClose);//Если пользователь уведомлен, то сохраняем
          }
          this.repeatCheckNotified=true;
          this._cdr.detectChanges();
        });
        this.loading = false;
      }      
    }
  }

  saveBlock() {
    if (this.popupState === this.popupStateType.block) {
      this.blockApiService.createBlock$.next(true);
      this.blockApiService.updateBlocksList$
        .pipe(
          untilDestroyed(this),
          first(),
          filter(v => v)
        ).subscribe(_ => this.popupRef.close());
    }
    if (this.popupState === this.popupStateType.selectLesson) {
      this.popupRef.close({ status: false, historyLessons: [...this.historyLessons] });
      this.historyLessons = [];
    }
  }

  historySelectedChanged($event) {
    this.historyLessons = $event;
  }

  private prepareOptions(): ICreateMeetingPopupClose | ICreateSlotPopupClose {
    let options: ICreateMeetingPopupClose | ICreateSlotPopupClose = {
      status: true,
      eventId: this.data.groupLessonOptions.lessonId,
      start: this.newDataRange.start,
      end: this.newDataRange.end,
      lessonWillBeRecorded: this.currentPublishingPolicy.lessonWillbeRecorded,
      subject: this.data.groupLessonOptions.subject,
      intervalId: this.IntervalId!=0? this.IntervalId: null,
      homeWork_template_id: (this.selectedHomeworkId!=0? this.selectedHomeworkId:null) || null,
    };
    
    if (this.isGroupLesson || this.data.groupLessonOptions.joinIdentity) {
      options = {
        ...options,
        lessonDate: moment.utc(this.newDataRange.start).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        start: moment(this.newDataRange.start).utc().format('HH:mm'),
        end: moment(this.newDataRange.end).utc().format('HH:mm'),
        disciplineId: this.data.groupLessonOptions.selectedDisciplineId,
        lessonWillBeRecorded: this.currentPublishingPolicy.lessonWillbeRecorded,
        publishingPolicy: this.currentPublishingPolicy,
        lessonId: this.data.groupLessonOptions.lessonId,
        invitedUsers: this.select?.items?.map(x => x.id),
      };
    }
    console.log('options', options, this.isGroupLesson, this.data.groupLessonOptions.joinIdentity)
    return options;
  }

  cancel(): void {
    this.popupRef.close({ status: false, lessonId: this.groupLessonId, subject: this.lessonSubject });
  }

  deleteLesson(): void {
    if (this.IntervalId == null || this.IntervalId==0){
      this.popupRef.close(null);
    }
    else {
      this.cancelPopup = true;
    }
  }

  toggleAccessToArchiveLesson(): void {
    this.currentPublishingPolicy.accessToArchiveLesson = this.isAccessToArchiveLessonForAll
      ? AccessToArchiveLesson.MembersOnly
      : AccessToArchiveLesson.All;
  }

  toggleRecordLesson(): void {
    this.currentPublishingPolicy.lessonWillbeRecorded = !this.currentPublishingPolicy.lessonWillbeRecorded
  }

  onChangeLessonPrivacy($event?: MatRadioChange) {
    if (!!this.warningPrivateMeetingMsg?.length && $event.value !== this.chatLessonPrivacyEnum.Private) {
      this.warningPrivateMeetingMsg = null;
    }

    if ($event.value === this.chatLessonPrivacyEnum.Public) {
      this.membersDefaultValue = this.select?.items;
    }
  }

  private validateMeetingTime(): boolean {
    const error = this.getMeetingTimeValidationError(this.newDataRange);
    if (error) {
      this.warningTimeMsg = error;
    }
    return !error;
  }

  private validatePrivateMeeting(): boolean {
    const error = this.getPrivateMeetingValidationError(this.currentPublishingPolicy?.lessonPrivacy, this.select?.items);
    this.warningPrivateMeetingMsg = error;
    return !error;
  }

  private getMeetingTimeValidationError(range: IDateRange): string | null {
    if (new Date().getTime() > range.start.getTime()) {
      return this.translocoService.translate('Время начала не может быть в прошлом');
    }
    if (range.start.getTime() > range.end.getTime()) {
      return this.translocoService.translate('Начало позже конца');
    }

    const duration = (range.end.getTime() - range.start.getTime()) / 60000;
    if (duration < 30) {
      return this.translocoService.translate('Создание события не может быть меньше 30 минут.');
    }

    if (this.currentMeetings) {
      const meeting = this.currentMeetings.find(
        x =>
          (fixFullcalendarBugOffset(x._instance.range.start).getTime() < range.start.getTime() &&
            fixFullcalendarBugOffset(x._instance.range.end).getTime() > range.start.getTime()) ||
          (fixFullcalendarBugOffset(x._instance.range.start).getTime() < range.end.getTime() &&
            fixFullcalendarBugOffset(x._instance.range.end).getTime() > range.end.getTime()),
      );
      if (meeting) {
        return 'Диапазон времени занят';
      }
    }
    return null;
  }

  private getPrivateMeetingValidationError(lessonPrivacy: ChatLessonPrivacy, members: any[]): string | null {
    if (lessonPrivacy === this.chatLessonPrivacyEnum.Private && !members?.length
    ) {
      return this.translocoService.translate('Закрытое занятие не может быть без участников');
    }
    return null;
  }

  changeTab(event: MatTabChangeEvent): void {
    this.calendarPopupService.isGroupLesson$.next(
      event.tab.textLabel === this.translocoService.translate(this.dataUI.groupLessonLabel),
    );
    if (event.tab.textLabel === this.translocoService.translate(this.dataUI.selectBlockLabel)) {
      this.popupState = PopupState.selectLesson;
    }
    if (event.tab.textLabel === this.translocoService.translate(this.dataUI.groupLessonLabel)) {
      this.popupState = PopupState.groupLesson;
    }
    if (event.tab.textLabel === this.translocoService.translate(this.dataUI.blockLabel)) {
      this.popupState = PopupState.block;
    }
  }

  confirmDeletion():void {//Подтверждение удаления
    if (this.deleteAll==1){
      this.popupRef.close(null);
    }
    else {
      this.calendarService.apiV1CLessonIntervalsDelete(this.data.groupLessonOptions.lessonId).subscribe(()=>{this.popupRef.close({ status: false})});
    }    
  }

  cancelDeletion(): void{
    this.cancelPopup=false;
  }

  editLesson(): void {
    const preparedOptions = this.prepareOptions();
    console.log(preparedOptions as ICreateMeetingPopupClose)
    if (!this.validatePrivateMeeting()) {
      return;
    }

    const equal = JSON.stringify(preparedOptions) === this.initialData;
    if (equal) {
      this.popupRef.close();
      return;
    }
    if (this.validateMeetingTime()) {
      if (this.repeatCheckNotified || this.IntervalId==null || this.IntervalId==0){
        this.popupRef.editLesson(preparedOptions as ICreateMeetingPopupClose);//Если пользователь уведомлен, то сохраняем
      }
      else {
        this.calendarService.ApiV1CalendarGroupValidateIntervalPost(
          {
            IntervalId: this.IntervalId!=0? this.IntervalId: null,
            lessonDate: moment.utc(this.newDataRange.start).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            LessonStartTime: moment(this.newDataRange.start).utc().format('HH:mm'),
            LessonEndTime: moment(this.newDataRange.end).utc().format('HH:mm'),
            InvitedUsers: this.select?.items?.map(x => x.id),
            ScheduledLessonId: this.data.groupLessonOptions.lessonId
          }
        ).subscribe(res=>{
          if (res?.body?.data?.occupiedDates && res?.body?.data?.occupiedDates.length>0){
            this.warningIntervalMsg=this.translocoService.translate("У вас уже заняты слоты")+": " +res?.body?.data?.occupiedDates.map((d:string)=>moment(new Date(d)).utc().format('DD.MM.YYYY')).join("; ");
          }
          else{
            this.popupRef.editLesson(preparedOptions as ICreateMeetingPopupClose);//Если пользователь уведомлен, то сохраняем                     
            
          }
          this.repeatCheckNotified=true;
          this._cdr.detectChanges();
        });
        this.loading = false;
      }
    }
  }

  editSlot(): void {
    if (this.validateMeetingTime()) {
      const { eventId, start, end } = this.prepareOptions() as ICreateSlotPopupClose;
      const options: ICreateSlotPopupClose = {
        eventId: +eventId,
        start,
        end,
      };
      this.popupRef.editSlot(options);
    }
  }

  closeApiErrorMessage(): void {
    if (!this.warningApiMsg?.length) {
      return;
    }
    this.calendarPopupService.errorAPI$.next(null);
  }

  displayWithUser(user: User | null): string {
    return user?.firstName + ' ' + user?.lastName;
  }

  onClosedMembersSelect(): void {
    this.validatePrivateMeeting();
  }

  subjectChanged($event) {
    this.data.groupLessonOptions.subject = $event;
  }

  selectHomeWork($event) {
    this.selectedHomeworkId = $event.value;
    this.selectedHomeworkName = this.homeworks?.find(h => h.id === this.selectedHomeworkId)?.name;
    if (this.selectedHomeworkId == 0){
      this.selectedHomeworkName=this.translocoService.translate("Не указано");
    }
  }

  selectPeriod($event) {
    this.IntervalId = $event.value;
    this.selectedIntervalName = this.intervals?.find(h => h.sortIndex === this.IntervalId)?.name;
    this.repeatCheckNotified=false;
  }


  onOpenedHomeworkSelect(event: any) {
    if (event && (this.totalRecords !== this.homeworks?.length)) {
      fromEvent(this.homeworkSelectElement.panel.nativeElement, 'scroll')
        .pipe(
          untilDestroyed(this),
          takeUntil(this.homeworkSelectDestroyed$)
        )
        .subscribe(_ => {
          if (this.totalRecords === this.homeworks?.length) {
            this.onDestroyHomeworkSelect();
            return;
          }

          const homeworkSelectNativeElement = this.homeworkSelectElement.panel.nativeElement;
          if (
            homeworkSelectNativeElement.scrollTop ===
            homeworkSelectNativeElement.scrollHeight -
            homeworkSelectNativeElement.offsetHeight
          ) {
            this.loadHomeworkTemplates(this.data?.groupLessonOptions?.selectedDisciplineId, ++this.homeworkPageIndex);
          }
        });
    }
  }
  onClosedHomeworkSelect() {
    this.onDestroyHomeworkSelect();
  }

  onDestroyHomeworkSelect() {
    this.homeworkSelectDestroyed$.next(true);
    this.homeworkSelectDestroyed$.complete();
  }

  onChangeDisciplineSelect(event: MatSelectChange) {
    this.currentPage = 1;
    this.homeworks = [];
    this.selectedHomeworkId = 0;
    this.selectedHomeworkName = this.translocoService.translate("Не указано");
    
    this.load$.next({
      disciplineId: this.data?.groupLessonOptions?.selectedDisciplineId,
      pageNumber: this.homeworkPageIndex
    });
  }
}

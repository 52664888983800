<div class="profile">
  <div class="profile_header">
    <div class="profile_header__left">
      <div class="profile_header__title">{{ 'Личный кабинет' | transloco }}</div>
    </div>
    <div class="profile_header__right">
      <div class="profile_header__title">{{ title | transloco }}</div>
    </div>
  </div>

  <div class="profile_content">
    <div class="profile_content__left">
      <div class="profile_image" id="avatarImage">
          
        
        <div class="avatar-image"
          *ngIf="profile?.userAvatar | userAvatar as image; else avatarPlaceholderTemplate"
          
        ><photo-crop 
        [imageUrl]="profile.userAvatar | userAvatar"
        [scale]="scale"
        [offsetX]="offsetX"
        [offsetY]="offsetY"
        (onSaveCrop)="saveAvatarSettings($event)"
        ></photo-crop></div>
        <ng-template #avatarPlaceholderTemplate>
          <div class="profile_image__letters">{{ user | userInitials | uppercase }}</div>
        </ng-template>

        <input
          id="avatar" 
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          (change)="avatarChange($event)"
          #file
        />
        <span
          (click)="file.click()" 
          class="button button-avatar"
        >
          <svg-icon-sprite
            [src]="'icon-upload-image'"
            [width]="'23px'"
            [height]="'23px'"
            [viewBox]="'0 0 23 23'"
          ></svg-icon-sprite>
        </span>
      </div>
      <div class="profile_name">{{ user?.name }}</div>
      <span class="uId">{{ user.uId }}</span>
      <div class="profile_nav">
        <div class="profile_nav__item">
          <a
            routerLink="/profile/personal"
            routerLinkActive="active"
          >
            {{ 'Общая информация' | transloco }}</a
          >
        </div>
      </div>
    </div>
    <div class="profile_content__right">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

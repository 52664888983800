<div
  class="messages"
  [ngClass]="{
    'without-note': !note,
    tutor: userdata?.isTutor,
    'isRoom': roomId
  }"
>

  <div class="messages-header">
    <ng-template *ngTemplateOutlet="controls"></ng-template>
  </div>

  <div class="messages-body">
    <div
      class="messages__left"
      [class.has-rooms]="rooms?.length"
    >
      <div
        class="messages-info"
        *ngIf="!rooms?.length"
      >
        {{ 'Пока что у вас нет сообщений' | transloco }} . <br />
        {{ 'Но в будущем тут будут отображаться ваши диалоги и адресаты' | transloco }}
      </div>


      <div
        class="search-result-header"
        *ngIf="searchInputValue !== ''"
      >
        <div
          class="icon"
          (click)="clearSearch()"
        >
          <ng-template *ngTemplateOutlet="backIcon"></ng-template>
        </div>
        <div class="title">
          Результаты поиска
        </div>
      </div>


      <div
        class="messages__list"
        [class]="{hide: isMobile && !rooms?.length}"
      >
        <div
          class="messages_list_item"
          [class]="{ current: room.id === roomId }"
          *ngFor="let room of filteredRooms"
          (click)="setRoom(room.id)"
        >
          <div class="inner">
            <div *ngFor="let user of room.chatRoomUsers">
              <div
                class="messages_list_item__author"
                *ngIf="user.id != userId"
              >
                <div class="messages_list_item__image">
                  <div
                    class="avatar-image"
                    *ngIf="image(userAvatarData?.[user.id])"
                    [style.background]="image(userAvatarData?.[user.id])"
                  ></div>

                  <span *ngIf="!image(user)">{{ initials(user) }}</span>
                  <div
                    class="is-online"
                    [class.active]="user.isOnline"
                  ></div>
                </div>

                <span
                  class="badge"
                  *ngIf="unreadMessages[room.id]?.length > 0"
                >{{ unreadMessages[room.id]?.length }}</span>

                <div class="messages_list_item__info">
                  <div class="messages_list_item__name">
                    <div class="text">{{ user.name }}</div>
                    <div class="time">{{ getMessageTime(room.messages) }}</div>
                  </div>
                  <div class="messages_list_item__text">
                    {{ room.messages?.length ? room.messages[0].messageText : 'Сообщений нет' |
                    transloco }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="search-result-header"
          *ngIf="searchInputValue !== '' && filteredRooms?.length"
        >
          <div
            [class.collapsed]="!showResults"
            class="icon toggle"
            (click)="toggleResults()"
          >
            <ng-template *ngTemplateOutlet="toggleIcon"></ng-template>
          </div>
          <div class="title">
            Найдено {{searchAllMessages?.length}} {{declineWord(searchAllMessages?.length || 0, 'сообщение',
            'сообщения', 'сообщений')}}
          </div>
        </div>

        <div
          class="search-results"
          *ngIf="searchAllMessages?.length && showSearch && showResults"
        >
          <div *ngFor="let msg of searchAllMessages">
            <div class="search-result">
              <div class="header">
                <div class="category"></div>
                <div class="time">
                  {{msg.timeDisplay}}
                </div>
              </div>
              <div class="body">
                <div class="author">
                  {{msg.sentByUser?.name}}:
                </div>
                <div class="text">
                  {{msg.messageText}}
                </div>
                <div class="image"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template *ngTemplateOutlet="emptyBlocks"></ng-template>
    </div>
    <div
      class="messages__right"
      [ngClass]="{ '--chatIsOpen': roomId }"
    >

      <div
        class="messages-body-header"
        *ngIf="roomId"
      >
        <div
          class="messages__back"
          (click)="clearRoom()"
        >
          <ng-template *ngTemplateOutlet="backIcon"></ng-template>
        </div>
        <messages_top
          class="messages__top"
          [roomId]="roomId"
          [roomUser]="roomUser"
          [stat]="stat"
          *ngIf="roomId"
        ></messages_top>
      </div>

      <ng-container *ngFor="let room of rooms">
        <div
          class="room"
          [class]="{ isActive: room.id === roomId }"
          *ngIf="room.id === roomId"
        >
          <ezteach-group-lesson-chat-merge
            class="widget-call-chat"
            *ngIf="room.id"
            [isMobile]="isMobile"
            (onMessagesChange)="onChangeMessages($event, room.id)"
            (onUnreadMessagesChange)="onChangeUnreadMessages($event, room.id)"
            [isGroupLessonChat]="false"
            [chatRoomId]="room.id"
            [chatIsOpen]="room.id === roomId"
            [roomIdChangeTrigger]="room.id === roomId"
          ></ezteach-group-lesson-chat-merge>
        </div>
      </ng-container>

      <ng-template *ngTemplateOutlet="emptyBlocks"></ng-template>
    </div>
  </div>

</div>

<ng-template #controls>
  <div class="controls">
    <div class="control">
      <app-select-component
        [minWidth]="'175px'"
        [hasClearButton]="false"
        [placeholder]="'Предмет'"
        [selectedValue]="selectDisplayRooms[0]"
        [options]="selectDisplayRooms"
      ></app-select-component>
    </div>
    <div class="control">
      <div
        class="control-btn"
        (click)="members()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
        >
          <path
            stroke="#92929D"
            stroke-linecap="round"
            stroke-linejoin="bevel"
            stroke-width="2"
            d="M8 1v14M1 8h14"
          />
        </svg>
      </div>
    </div>
    <div class="control">
      <div class="search">
        <span
          class="control-btn"
          (click)="toggleSearch()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
          >
            <path
              fill="#92929D"
              fill-rule="evenodd"
              d="M2.733 13.694a7.633 7.633 0 1 1 11.498-.807l3.012 3.012c.786.786-.393 1.964-1.178 1.178l-2.976-2.976a7.634 7.634 0 0 1-10.356-.407Zm9.617-9.616a5.967 5.967 0 1 0-8.438 8.438 5.967 5.967 0 0 0 8.438-8.438Z"
              clip-rule="evenodd"
            />
          </svg>
        </span>

        <div
          class="search-box"
          *ngIf="showSearch"
        >
          <div class="input">
            <input
              type="text"
              placeholder="Поиск"
              (input)="searchMessages($event)"
              [value]="searchInputValue"
            >
          </div>

          <ezteach-btn
            [type]="buttonType.notFilled"
            [width]="'100px'"
            [height]="'38px'"
            class="button"
          >
            <div
              content
              (click)="clearSearch()"
            >{{ 'Отменить' | transloco }}</div>
          </ezteach-btn>
        </div>
      </div>
    </div>
  </div>
</ng-template>



<ng-template #emptyBlocks>
  <div
    class="messages_none"
    *ngIf="!rooms?.length"
  >
    <div class="messages_none__wrap">
      <div class="messages_none__icon">
        <img src="/assets/img/content/icon_messages.svg" />
      </div>
      <div class="messages_none__title">{{ 'Еще никто не написал' | transloco }}?</div>
      <div class="messages_none__text">
        {{ 'Вы всегда можете уточнить время, дату и домашнее задание в нашем мессенджере' | transloco }}
      </div>
      <div class="messages_none__action">
        <a
          class="btn btn--blue"
          (click)="members()"
        >
          <span class="btn__label"> {{ 'Новое сообщение' | transloco }}</span>
        </a>
      </div>
    </div>
  </div>
  <div
    class="messages_none"
    *ngIf="rooms?.length && !roomId"
  >
    <div class="messages_none__wrap">
      <div class="messages_none__icon">
        <img src="/assets/img/content/icon_messages.svg" />
      </div>
      <div class="messages_none__title">{{ 'Выберите чат, чтобы начать переписку' | transloco }}</div>
    </div>
  </div>
</ng-template>

<ng-template #backIcon>
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="#92929D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="bevel"
      d="M10.45 3.054 5.5 8.003M10.445 12.96l-4.95-4.95"
    />
  </svg>
</ng-template>

<ng-template #toggleIcon>
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="#92929D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="bevel"
      d="M12.946 10.45 7.997 5.5M3.04 10.445l4.95-4.95"
    />
  </svg>
</ng-template>
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { HomeWorkTemplate } from '@ezteach/api/models/homework';
import { LessonsService } from '@ezteach/api/services/lessons.service';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import { HomeworkTemplatePagedApiResponse, HomeworksService } from '@ezteach/homeworks/homeworks.service';
import { ISortParams } from '@ezteach/homeworks/sort-bar/sort-bar.component';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, fromEvent } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ezteach-create-homework-popup',
  templateUrl: './add-homework-popup.component.html',
  styleUrls: ['./add-homework-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddHomeworkPopupComponent implements OnInit {
  @Output() addHomeworkToolbar = new EventEmitter<number>();
  @ViewChild('homeworkSelectElement') homeworkSelectElement: MatSelect;
  buttonType = ButtonType;
  totalRecords = 0;
  take = 100;

  homeworks: HomeWorkTemplate[] = [];
  load$ = new Subject<number>();
  sortParam: ISortParams | null = null;
  isLoadedHomeworkPage: boolean = false;

  private homeworkSelectDestroyed$ = new Subject<boolean>();
  private homeworkPageIndex: number = 1;

  selectedHomeworkId: number | null = 0;
  selectedHomeworkName: string;
  selectedHomework: HomeWorkTemplate | null = null;
  isLoadedSelectedHomework: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddHomeworkPopupComponent>,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    private homeworkService: HomeworksService,
    private lessonService: LessonsService,
  ) { }

  ngOnInit(): void {
    this.selectedHomework = this.data?.homeWorkTemplate;
    this.isLoadedSelectedHomework = !this.selectedHomework?.id?.toString()?.length;

    this.selectedHomeworkId = !!this.selectedHomework?.id?.toString()
      ? this.selectedHomework?.id
      : 0;
    this.selectedHomeworkName = this.selectedHomework?.name ?? this.translocoService.translate("Не указано");

    this.load$
      .pipe(
        tap(_ => this.isLoadedHomeworkPage = false),
        untilDestroyed(this),
        switchMap(x => this.homeworkService.getHomeworkTemplates(this.configureRequestData(x))),
      )
      .subscribe(x => {
        this.isLoadedHomeworkPage = true;
        this.getDataSource(x);
      });
    this.load$.next(this.homeworkPageIndex);
    this.cdr.detectChanges();
  }
  close() {
    this.dialogRef.close();
    this.onDestroyHomeworkSelect();
  }
  selectHomeWork($event) {
    this.selectedHomeworkId = $event.value;

    this.selectedHomework = this.homeworks?.find(h => h.id === this.selectedHomeworkId);

    this.selectedHomeworkName = this.selectedHomework?.name;
    if (this.selectedHomeworkId == 0) {
      this.selectedHomeworkName=this.translocoService.translate("Не указано");
    }
  }
  configureRequestData(PageNumber: number) {
    let requestData = {
      PageNumber,
      PageSize: this.take,
      SortByDesc: 'id',
    };
    if (this.sortParam) {
      requestData = { ...requestData, ...this.sortParam };
    }
    return requestData;
  }
  getDataSource(response: StrictHttpResponse<HomeworkTemplatePagedApiResponse>) {
    this.totalRecords = response?.body?.data ? response?.body?.data?.totalRecords : 0;
    if (this.totalRecords === 0) {
      this.homeworks = [];
      this.cdr.detectChanges();
      return;
    }


    if (this.totalRecords === this.homeworks?.length) {
      return;
    }

    if (!this.isLoadedSelectedHomework &&
      !!response?.body?.data?.records?.length &&
      !!response?.body?.data?.records.find(h => h.id === this.selectedHomework?.id)
    ) {
      this.isLoadedSelectedHomework = true;
    }

    this.homeworks = this.homeworks.concat(response?.body?.data?.records);

    this.cdr.detectChanges();
  }
  save() {
    const defaultSelectedHomework: HomeWorkTemplate = this.data?.homeWorkTemplate;
    console.log(this.selectedHomework)
    if (this.data.isBlock) {
      this.dialogRef.close(this.selectedHomework?this.selectedHomework: null);
      return null;
    } else if (defaultSelectedHomework?.id === this.selectedHomework?.id) {
      this.dialogRef.close(this.selectedHomework?this.selectedHomework: null);
      return null;
    } else {
      const paramsId = {
        lessonId: this.data.idLesson,
        homeWorkTemplateId: this.selectedHomework?.id!=0?this.selectedHomework?.id: null,
      };
      this.dialogRef.close(this.selectedHomework?this.selectedHomework: null);
      if (this.data.isStarted) {
        this.lessonService.addHomework(paramsId).subscribe();
      }
      return this.lessonService.addedHomeworkInLesson(paramsId).pipe(take(1)).subscribe();
    }
  }
  onOpenedHomeworkSelect(event: any) {
    if (event && (
      (!this.isLoadedHomeworkPage && !this.totalRecords) ||
      (this.totalRecords !== this.homeworks?.length)
    )) {
      fromEvent(this.homeworkSelectElement.panel.nativeElement, 'scroll')
        .pipe(
          untilDestroyed(this),
          takeUntil(this.homeworkSelectDestroyed$)
        )
        .subscribe(_ => {
          if (this.totalRecords === this.homeworks?.length) {
            this.onDestroyHomeworkSelect();
            return;
          }

          const homeworkSelectNativeElement = this.homeworkSelectElement.panel.nativeElement;
          if (
            homeworkSelectNativeElement.scrollTop ===
            homeworkSelectNativeElement.scrollHeight -
            homeworkSelectNativeElement.offsetHeight
          ) {
            this.load$.next(++this.homeworkPageIndex);
          }
        });
    }
  }
  onClosedHomeworkSelect() {
    this.onDestroyHomeworkSelect();
  }
  onDestroyHomeworkSelect() {
    this.homeworkSelectDestroyed$?.next(true);
    this.homeworkSelectDestroyed$?.complete();
  }
}

<div class="messages__title">
  <ng-container *ngIf="roomUser">
    <a
      routerLink="/teachers/{{ roomUser.id }}"
      *ngIf="userdata.isStudent; else tutorTitleComponent"
      class="message_user"
    >
      <span class="message_user__image">
        <div
          class="avatar-image"
          *ngIf="roomUser | userAvatar; else showUserInitials"
          [style.background]="roomUser | userAvatarBackground"
        ></div>
        <ng-template #showUserInitials>
          <span>{{ roomUser | userInitials }}</span>
        </ng-template>
      </span>
      <span class="message_user__name">{{ roomUser?.name }}</span>
    </a>

    <ng-template #tutorTitleComponent>
      <span class="message_user">
        <span class="message_user__image">
          <div
            class="avatar-image"
            *ngIf="roomUser | userAvatar; else showTutorInitials"
            [style.background]="roomUser | userAvatarBackground"
          ></div>
          <ng-template #showTutorInitials>
            <span>{{ roomUser | userInitials }}</span>
          </ng-template>
        </span>
        <span class="message_user__name">{{ roomUser?.name }}</span>
      </span>
    </ng-template>
  </ng-container>
</div>
<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  class="icon-menu"
  (click)="getStat()"
>
  <svg
    width="4"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="2"
      cy="2"
      r="1.5"
      fill="#737173"
    />
    <circle
      cx="2"
      cy="8"
      r="1.5"
      fill="#737173"
    />
    <circle
      cx="2"
      cy="14"
      r="1.5"
      fill="#737173"
    />
  </svg>
</button>
<mat-menu
  #menu="matMenu"
  class="messages_menu"
  xPosition="before"
>
  <button
    mat-menu-item
    *ngIf="stat"
    class="messages_menu__item"
    (click)="openModal('images')"
  >
    <img src="/assets/img/svg/icon-images.svg" />
    <span>{{ 'Фотографии' | transloco }} {{ stat.imagesCount }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="stat"
    class="messages_menu__item"
    (click)="openModal('files')"
  >
    <img src="/assets/img/svg/icon-files.svg" />
    <span>{{ 'Файлы' | transloco }} {{ stat.filesCount }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="stat"
    class="messages_menu__item"
    (click)="openModal('links')"
  >
    <img src="/assets/img/svg/icon-links.svg" />
    <span>{{ 'Ссылки' | transloco }} {{ stat.linksCount }}</span>
  </button>
</mat-menu>
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { isProfileSaving, saveProfilePatron, selectStudentPatron, setProfileTitle } from '../../+state';
import { Patron, StudentPatron, UserProfile } from '../../../api/models';

@UntilDestroy()
@Component({
  selector: 'app-profile-patron',
  templateUrl: './profile-patron.component.html',
  styleUrls: ['./profile-patron.component.scss'],
})
export class ProfilePatronComponent implements OnInit {
  profile: UserProfile;
  form: UntypedFormGroup;
  studentPatron: StudentPatron;

  title = $localize`Данные родителя`;
  saving$: Observable<boolean>;
  isReadonlyFields: boolean = true;

  constructor(private formBuilder: UntypedFormBuilder, private store: Store) { }

  ngOnInit() {
    this.createForm();
    this.store.dispatch(setProfileTitle({ title: this.title }));
    this.subscribeStore();
  }

  subscribeStore() {
    this.store
      .pipe(
        untilDestroyed(this),
        select(selectStudentPatron),
        filter(studentPatron => studentPatron),
        take(1),
        tap(studentPatron => {
          this.studentPatron = studentPatron;
          this.updateForm(studentPatron.patron);
          this.isReadonlyFields = false;
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving), tap(isProfileSaving => {
      if (this.isReadonlyFields && !isProfileSaving) {
        this.isReadonlyFields = false;
      }
    }));
  }

  createForm() {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(20)]],
      lastName: ['', [Validators.required, Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^(\+7(?!7|8)\d{10}$)|^(\+(?!7)\d{7,16}$)|^((?!7)\d{7,16}$)/)]],
    });
  }

  updateForm(data: Patron) {
    this.form.patchValue({
      ...data,
      phone: (data.phone || ''),
    });
  }

  setProfile() {
    this.isReadonlyFields = true;
    const value = this.form.value;
    const profilePatron = {
      ...this.studentPatron,
      patron: {
        ...this.studentPatron.patron,
        ...value,
        phone: `${value.phone}`,
      },
    };

    this.store.dispatch(saveProfilePatron(profilePatron));
  }
}

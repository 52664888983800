<div class="container">
  <div class="discipline">
    {{ disciplineName }}

    <span
      class="close"
      (click)="cancel()"
    >
      <img
        src="assets/img/svg/icon-close_dark.svg"
        alt="closeButton"
      />
    </span>
  </div>
  <div class="theme">
    {{ themeLesson }}
  </div>
  <div class="info">
    <div class="date-time">
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.6h-.833V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234A.784.784 0 0 0 10.5.8v.8h-5V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234.784.784 0 0 0-.244.566v.8H3c-.663 0-1.299.253-1.768.703A2.352 2.352 0 0 0 .5 4v9.6c0 .636.263 1.247.732 1.697.47.45 1.105.703 1.768.703h10c.663 0 1.299-.253 1.768-.703.469-.45.732-1.06.732-1.697V4c0-.637-.263-1.247-.732-1.697A2.554 2.554 0 0 0 13 1.6ZM3 3.2h.833V4c0 .212.088.416.244.566.157.15.369.234.59.234a.851.851 0 0 0 .589-.234A.784.784 0 0 0 5.5 4v-.8h5V4c0 .212.088.416.244.566.156.15.368.234.59.234a.851.851 0 0 0 .589-.234.784.784 0 0 0 .244-.566v-.8H13c.221 0 .433.084.59.234.155.15.243.354.243.566v3.2H2.167V4c0-.212.087-.416.244-.566A.851.851 0 0 1 3 3.2Zm10 11.2H3a.851.851 0 0 1-.59-.234.784.784 0 0 1-.243-.566V8.8h11.666v4.8a.784.784 0 0 1-.244.566.852.852 0 0 1-.589.234Z"
            fill="#678AFF"
          />
          <path
            d="M4.8 12.1a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM11.167 10.5H7.833a.851.851 0 0 0-.589.234A.784.784 0 0 0 7 11.3c0 .212.088.416.244.566.156.15.368.234.59.234h3.333a.851.851 0 0 0 .589-.234A.784.784 0 0 0 12 11.3a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234Z"
            fill="#678AFF"
          />
        </svg>
      </div>
      <div
        class="date"
        *ngIf="lessonDate"
      >
        {{ lessonDate }}
      </div>
      <div
        class="time"
        *ngIf="lessonDateTime"
      >
        {{ lessonDateTime }}
      </div>
    </div>

    <div
      class="type-privacy"
      *ngIf="data?.lessonFormatId !== 'Scheduled' && data?.lessonFormatId !== 'Instant'"
    >
      <div class="type">
        {{ data.publishingPolicy?.publisher === 'Any' ? ('Семинар' | transloco) : ('Лекция' | transloco) }}
      </div>

      <div class="publicLesson">
        {{ isPublicLesson ? ('Открытое занятие' | transloco) : ('Закрытое занятие' | transloco) }}
      </div>
    </div>

    <div
      class="type-privacy"
      *ngIf="data?.lessonFormatId == 'Scheduled' || data?.lessonFormatId == 'Instant'"
    >
      <div class="publicLesson">
        {{ data?.lessonFormatId == 'Scheduled' ? ('Индивидуальное занятие' | transloco) : ('Быстрое занятие' | transloco) }}
      </div>
    </div>
  </div>

  <div class="members-wrapper">
    <div
      class="members"
      *ngIf="tutorName"
    >
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.8 13.12A5.72 5.72 0 0 1 7.52 7.4h.96a5.72 5.72 0 0 1 5.72 5.72A1.88 1.88 0 0 1 12.32 15H3.68a1.88 1.88 0 0 1-1.88-1.88Z"
            stroke="#678AFF"
            stroke-width="2"
          />
          <circle
            cx="8"
            cy="4"
            r="3"
            stroke="#678AFF"
            stroke-width="2"
          />
        </svg>
      </div>
      <div class="text-title">
        {{ 'Репетитор' | transloco }}
      </div>
    </div>
    <div class="members-list">
      {{ tutorName }}
    </div>
  </div>

  <div
    class="members-wrapper"
    *ngIf="countMembers > 0"
  >
    <div class="members">
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.8 13.12A5.72 5.72 0 0 1 7.52 7.4h.96a5.72 5.72 0 0 1 5.72 5.72A1.88 1.88 0 0 1 12.32 15H3.68a1.88 1.88 0 0 1-1.88-1.88Z"
            stroke="#678AFF"
            stroke-width="2"
          />
          <circle
            cx="8"
            cy="4"
            r="3"
            stroke="#678AFF"
            stroke-width="2"
          />
        </svg>
      </div>
      <div class="text-title">
        {{ countMembers > 1 ? 'Участники ' + countMembers : ('Участники' | transloco) }}
      </div>
    </div>
    <div class="members-list">
      {{ membersDisplayText }}
    </div>
  </div>

  <div
    class="control"
    *ngIf="data?.joinIdentity"
  >
    <ezteach-btn
      (click)="initiateLesson()"
      [type]="buttonType.filled"
      [width]="'100%'"
      [height]="'38px'"
    >
      <div content>{{ 'Перейти' | transloco }}</div>
    </ezteach-btn>
  </div>
</div>
